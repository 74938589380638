import { Fragment, useState, useEffect, useContext, useRef } from 'react'
import axios from "axios";

import { get, ref, onValue, startAt, orderByKey, limitToFirst, query, set } from "firebase/database";

import { db } from '../utils/firebaseApp.js';
import { AuthContext } from "../utils/auth.js";

import { Menu, Dialog, Transition } from '@headlessui/react'
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon, CheckIcon } from '@heroicons/react/24/outline'
import { ArrowLeftIcon, ArrowRightIcon, Bars3Icon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { TrashIcon as CustomTrashIcon, ArrowDownTrayIcon as CustomDownloadIcon, ClipboardDocumentIcon as CustomShareIcon } from '@heroicons/react/20/solid'
import { XMarkIcon } from '@heroicons/react/20/solid'

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import toast, { Toaster } from 'react-hot-toast';
import { handleGenerateJson, handleGenerateExcel, handleGenerateCsv, handleGenerateWord } from './functions/exportData.js';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ViewFile = ({ initialFileStatus, fileData, extractionId, batchId, extraction, closeModal }) => {
    const { currentUser } = useContext(AuthContext);
    let userId = currentUser.uid;

    const serverUrl = process.env.REACT_APP_SERVER_URL;

    const [isLoading, setIsLoading] = useState(false);

    const [fileUrl, setFileUrl] = useState(fileData.file_url);
    const [fileName, setFileName] = useState(fileData.file_name);
    const [fileId, setFileId] = useState(fileData.file_id);
    const [fileStatus, setFileStatus] = useState(fileData.status);

    const [showDeleteFile, setShowDeleteFile] = useState(false);

    const [files, setFiles] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    const prepereData = (data) => {
        let newData = {};
        extraction.fields.forEach((field) => {
            let key = field.key;
            let fieldType = field.type ?? 'string';
            let itemsType = field.items?.type ?? 'string';

            if (fieldType === "string") {
                newData[key] = data[key] ?? '';
            } else if (fieldType === "object") {
                let defaultObject = {};
                field.properties.forEach((innerField) => {
                    defaultObject[innerField.key] = '';
                });
                newData[key] = data[key] ?? defaultObject;
            } else if (fieldType === "array" && itemsType === "string") {
                newData[key] = data[key] ?? [''];
            } else if (fieldType === "array" && itemsType === "object") {
                let defaultObject = {};
                field.items.properties.forEach((innerField) => {
                    defaultObject[innerField.key] = '';
                });
                newData[key] = data[key] ?? [defaultObject];
            }
        });
        return newData;
    }

    const [data, setData] = useState(prepereData(fileData.data));
    const [initialData, setInitialData] = useState(prepereData(fileData.data));

    const updateFileResult = async () => {
        await currentUser.getIdToken().then(async (idToken) => {
            const url = serverUrl + "/updateFileResult";

            await axios.post(url, {
                "extractionId": extractionId,
                "batchId": batchId,
                "fileId": fileId,
                "data": data,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${idToken}`
                }
            }).then(async (response) => {
                if (response.status === 200) {
                    toast.success('File result updated successfully!');

                    setInitialData(data);
                    setData(data);

                    // update files data
                    let newFiles = files.map((file) => {
                        if (file.id === fileId) {
                            file.result = data;
                        }
                        return file;
                    });
                    setFiles(newFiles);
                } else {
                    toast.error('Error updating file result!');
                }
            }).catch((error) => {
                toast.error('Error updating file result!');
            });
        });
    }

    const handleInputChangeString = (key, value) => {
        setData({
            ...data, [key]: value
        });
    }

    const handleInputChangeObject = (key, subKey, value) => {
        setData({
            ...data,
            [key]: {
                ...data[key],
                [subKey]: value
            }
        });
    }

    const handleInputChangeArrayString = (key, index, value) => {
        setData({
            ...data,
            [key]: data[key].map((item, i) => {
                if (i === index) {
                    return value;
                } else {
                    return item;
                }
            })
        });
    }

    const handleInputChangeArrayObject = (key, index, subKey, value) => {
        setData({
            ...data,
            [key]: data[key].map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        [subKey]: value
                    };
                } else {
                    return item;
                }
            })
        });
    }

    const addInputArrayString = (key) => {
        if (data[key].some((item) => item === '')) {
            return;
        }

        setData({
            ...data,
            [key]: [...data[key], '']
        });
    }

    const addInputArrayObject = (key) => {
        if (data[key].some((item) => Object.values(item).every((value) => value === ''))) {
            return;
        }

        let defaultObject = {};
        let fields = extraction.fields.find((field) => field.key === key).items.properties;

        fields.forEach((innerField) => {
            defaultObject[innerField.key] = '';
        });

        setData({
            ...data,
            [key]: [...data[key], defaultObject]
        });
    }

    const deleteInputArrayString = (key, index) => {
        setData({
            ...data,
            [key]: data[key].filter((item, i) => i !== index)
        });
    }

    const deleteInputArrayObject = (key, index) => {
        setData({
            ...data,
            [key]: data[key].filter((item, i) => i !== index)
        });
    }

    const isWordOrError = (fileType) => {
        return (fileType !== 'pdf' && fileType !== 'txt' && fileType !== 'image' && fileType !== 'error');
    }

    const getFileTypeFromUrl = (url) => {
        try {
            const path = url.split('?')[0];
            const extension = path.split('.').pop();
            if (['png', 'jpg', 'jpeg', 'gif', 'bmp', 'jfif'].includes(extension)) return 'image';
            return extension;
        } catch (error) {
            return 'error';
        }
    };

    const updateFileStatus = async () => {
        await currentUser.getIdToken().then(async (idToken) => {
            const url = serverUrl + "/updateFileStatus";

            await axios.post(url, {
                "extractionId": extractionId,
                "batchId": batchId,
                "fileId": fileId,
                "status": "confirmed",
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${idToken}`
                }
            }).then(async (response) => {
                if (response.status === 200) {
                    setFileStatus("confirmed");
                    toast.success('File status updated successfully!');

                    // update files data
                    let newFiles = files.map((file) => {
                        if (file.id === fileId) {
                            file.status = "confirmed";
                        }
                        return file;
                    });
                    setFiles(newFiles);

                    // go to next file if there is one, or go to previous or close the modal
                    if (isNextFile()) {
                        await nextFile(true);
                    } else if (isPreviousFile()) {
                        await previousFile(true);
                    } else {
                        await closePopup(true);
                    }
                } else {
                    toast.error('Error updating file status!');
                }
            }).catch((error) => {
                toast.error('Error updating file status!');
            });
        });
    }

    const hasDataChanged = () => {
        return JSON.stringify(data) !== JSON.stringify(initialData);
    }

    const isNextFile = () => {
        let currentFileId = fileId;
        let index = files.findIndex((file) => file.id === currentFileId);
        if (index === -1) return false;
        if (index === files.length - 1) return false;
        return true;
    }

    const isPreviousFile = () => {
        let currentFileId = fileId;
        let index = files.findIndex((file) => file.id === currentFileId);
        if (index === -1) return false;
        if (index === 0) return false;
        return true;
    }

    const nextFile = async (skipCheck = false) => {
        try {
            setIsLoading(true);
            await new Promise(resolve => setTimeout(resolve, 200));

            const isChangedData = hasDataChanged();

            if (isChangedData && !skipCheck) {
                setNextMoveAfterSaving('next');
                setShowUnsavedChangesAlert(true);
                return;
            }

            let currentFileId = fileId;
            let index = files.findIndex((file) => file.id === currentFileId);
            if (index === -1) return;
            if (index === files.length - 1) return;
            let nextFileId = files[index + 1].id;
            let nextFileData = files[index + 1];

            setFileId(nextFileId);
            setFileUrl(nextFileData.url);
            setFileName(nextFileData.name);
            setFileStatus(nextFileData.status);
            setData(prepereData(nextFileData.result ?? {}));
            setInitialData(prepereData(nextFileData.result ?? {}));
            setCurrentIndex(index + 1);
        } catch (error) {
        }

        setIsLoading(false);
    }

    const previousFile = async (skipCheck = false) => {
        try {
            setIsLoading(true);
            await new Promise(resolve => setTimeout(resolve, 200));

            const isChangedData = hasDataChanged();

            if (isChangedData && !skipCheck) {
                setNextMoveAfterSaving('previous');
                setShowUnsavedChangesAlert(true);
                return;
            }

            let currentFileId = fileId;
            let index = files.findIndex((file) => file.id === currentFileId);
            if (index === -1) return;
            if (index === 0) return;
            let previousFileId = files[index - 1].id;
            let previousFileData = files[index - 1];

            setFileId(previousFileId);
            setFileUrl(previousFileData.url);
            setFileName(previousFileData.name);
            setFileStatus(previousFileData.status);
            setData(prepereData(previousFileData.result ?? {}));
            setInitialData(prepereData(previousFileData.result ?? {}));
            setCurrentIndex(index - 1);
        } catch (error) {
        }
        setIsLoading(false);
    }

    const closePopup = async (skipCheck = false) => {
        try {
            setIsLoading(true);
            await new Promise(resolve => setTimeout(resolve, 200));

            const isChangedData = hasDataChanged();

            if (isChangedData && !skipCheck) {
                setNextMoveAfterSaving('close');
                setShowUnsavedChangesAlert(true);
                return;
            }

            closeModal();
        } catch (error) {

        }
    }

    const deleteFile = async () => {
        await currentUser.getIdToken().then(async (idToken) => {
            const url = serverUrl + "/delete";

            await axios.post(url, {
                "extractionId": extractionId,
                "batchId": batchId,
                "fileId": fileId,
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${idToken}`
                }
            }).then(async (response) => {
                setShowDeleteFile(false);

                if (response.status === 200) {
                    toast.success('File deleted successfully!');

                    await fetchFiles();

                    if (isNextFile()) {
                        await nextFile(true);
                    } else if (isPreviousFile()) {
                        await previousFile(true);
                    } else {
                        await closePopup(true);
                    }
                } else {
                    toast.error('Error deleting file!');
                }
            }).catch((error) => {
                setShowDeleteFile(false);
                toast.error('Error deleting file!');
            });
        });
    }

    const copyFileLink = async () => {
        navigator.clipboard.writeText(fileUrl);
        toast.success('File link copied to clipboard!');
    }

    const downloadFile = async () => {
        window.open(fileUrl, '_blank');
        toast.success('File opened successfully!');
    };

    const [extractionData, setExtractionData] = useState({});
    const [batchFiles, setBatchFiles] = useState([]);

    const [showUnsavedChangesAlert, setShowUnsavedChangesAlert] = useState(false);
    const [nextMoveAfterSaving, setNextMoveAfterSaving] = useState('');
    const [showExportModal, setShowExportModal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            let userId = currentUser.uid;
            const databaseRef = ref(db, 'extractions/' + userId + '/' + extractionId);

            try {
                const snapshot = await get(databaseRef);

                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const batchFiles = data.batches[batchId].files;

                    setExtractionData(data);
                    setBatchFiles(batchFiles);
                } else {
                    console.log("No data available");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        fetchData();
    }, [extractionId, batchId, currentUser]);

    const fetchFiles = async () => {
        // load all files and put them in the state
        let files = [];

        let userId = currentUser.uid;
        const databaseRef = ref(db, 'files/' + userId + '/' + extractionId + '/' + batchId);

        try {
            const snapshot = await get(databaseRef);

            if (snapshot.exists()) {
                const data = snapshot.val();

                if (data) {
                    for (let fileId in data) {

                        let file = data[fileId];
                        file.id = fileId;

                        if (initialFileStatus === 'All') {
                            files.push(file);
                        } else if ((file.status === 'processed' || file.status === 'waiting') && initialFileStatus === 'To Review') {
                            files.push(file);
                        } else if (file.status === 'confirmed' && initialFileStatus === 'Confirmed') {
                            files.push(file);
                        } else if (file.status === 'error' && initialFileStatus === 'Error') {
                            files.push(file);
                        }
                    }

                    setFiles(files);
                }
            } else {
                console.log("No data available");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            // load all files and put them in the state
            let files = [];

            let userId = currentUser.uid;
            const databaseRef = ref(db, 'files/' + userId + '/' + extractionId + '/' + batchId);

            try {
                const snapshot = await get(databaseRef);

                if (snapshot.exists()) {
                    const data = snapshot.val();

                    if (data) {
                        for (let fileId in data) {

                            let file = data[fileId];
                            file.id = fileId;

                            if (initialFileStatus === 'All') {
                                files.push(file);
                            } else if ((file.status === 'processed' || file.status === 'waiting') && initialFileStatus === 'To Review') {
                                files.push(file);
                            } else if (file.status === 'confirmed' && initialFileStatus === 'Confirmed') {
                                files.push(file);
                            } else if (file.status === 'error' && initialFileStatus === 'Error') {
                                files.push(file);
                            }
                        }

                        setFiles(files);
                    }
                } else {
                    console.log("No data available");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        fetchData();
    }, [extractionId, batchId, currentUser, initialFileStatus]);

    return (
        <div className='flex flex-col md:flex-row w-full h-full p-4 bg-white rounded-lg shadow-md overflow-auto'>
            <ShowDeleteModalFile
                open={showDeleteFile}
                setOpen={setShowDeleteFile}
                deleteFunction={deleteFile}
            />

            <ExportDialog
                open={showExportModal}
                setOpen={setShowExportModal}
                fileId={fileId}
                files={files}
                extraction={extraction}
                userId={userId}
                extractionId={extractionId}
            />

            <UnsavedChangesAlert
                open={showUnsavedChangesAlert}
                setOpen={setShowUnsavedChangesAlert}
                nextMoveAfterSaving={nextMoveAfterSaving}
                saveChanges={async () => {
                    await updateFileResult();

                    if (nextMoveAfterSaving === 'next') await nextFile(true);
                    if (nextMoveAfterSaving === 'previous') await previousFile(true);
                    if (nextMoveAfterSaving === 'close') await closePopup(true);

                    setShowUnsavedChangesAlert(false);
                    setIsLoading(false);
                }}
                discardChanges={async () => {
                    if (nextMoveAfterSaving === 'next') await nextFile(true);
                    if (nextMoveAfterSaving === 'previous') await previousFile(true);
                    if (nextMoveAfterSaving === 'close') await closePopup(true);

                    setShowUnsavedChangesAlert(false);
                    setIsLoading(false);
                }}
            />

            <div className="absolute right-0 top-0 pr-5 pt-5 sm:block">
                <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={async () => await closePopup()}
                >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>

            {(getFileTypeFromUrl(fileUrl) === 'pdf' || getFileTypeFromUrl(fileUrl) === 'txt') && (
                <iframe
                    title={fileName}
                    src={fileUrl}
                    className='md:w-3/5 md:h-full h-full md:mr-5 bg-slate-50 rounded-md border py-1'
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
            )}

            {getFileTypeFromUrl(fileUrl) === 'image' && (
                <img
                    src={fileUrl}
                    alt={fileName}
                    className="md:w-3/5 md:h-full md:mr-5 bg-slate-50 rounded-md border object-contain py-1"
                />
            )}

            <div className={classNames(
                'h-full flex flex-col mr-1',
                (isWordOrError(getFileTypeFromUrl(fileUrl)) ? 'w-full' : 'md:w-2/5')
            )}>
                <h2 className='mt-4 md:mt-0 text-2xl font-bold'>
                    Edit file result
                </h2>

                <div className='flex items-center mt-3'>
                    <span className='font-bold'>File Name:</span>
                    {isLoading && <Skeleton width={100} height={20} className="ml-2" />}
                    {!isLoading && <span className='ml-2'>{fileName}</span>}
                </div>

                <div className='flex items-center mt-2'>
                    <span className='font-bold'>File Status:</span>
                    {isLoading && <Skeleton width={100} height={20} className="ml-2" />}
                    {!isLoading && <span className='ml-2'>{fileStatus === "waiting" ? "processing" : fileStatus}</span>}
                </div>

                <div className='mt-4 flex-grow rounded-lg md:overflow-auto pr-4'>
                    <DisplayResult
                        extractionId={extractionId}
                        batchId={batchId}
                        fileId={fileId}
                        result={data}
                        extraction={extraction}
                        handleInputChangeString={handleInputChangeString}
                        handleInputChangeObject={handleInputChangeObject}
                        handleInputChangeArrayString={handleInputChangeArrayString}
                        handleInputChangeArrayObject={handleInputChangeArrayObject}
                        addInputArrayString={addInputArrayString}
                        addInputArrayObject={addInputArrayObject}
                        deleteInputArrayString={deleteInputArrayString}
                        deleteInputArrayObject={deleteInputArrayObject}
                        isLoading={isLoading}
                    />
                </div>

                {!hasDataChanged() && (
                    <div className='mt-10 flex items-center justify-between'>
                        {fileStatus === 'processed' && (
                            <button
                                onClick={updateFileStatus}
                                className={classNames(
                                    "w-full inline-flex items-center justify-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                                    isLoading && 'opacity-50 cursor-not-allowed'
                                )}
                            >
                                <CheckIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                Confirm
                            </button>)}

                        {fileStatus === 'confirmed' && (
                            <button
                                onClick={updateFileStatus}
                                className={classNames(
                                    "w-full inline-flex items-center justify-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                                    'opacity-50 cursor-not-allowed'
                                )}
                                disabled={fileStatus === 'confirmed'}
                            >
                                <CheckIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                Confirmed
                            </button>)}
                    </div>
                )}

                {hasDataChanged() && (
                    <div className='mt-10 flex items-center justify-between'>
                        <button
                            onClick={updateFileResult}
                            className={classNames(
                                "w-full inline-flex items-center justify-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                                (!hasDataChanged() || isLoading) && 'opacity-50 cursor-not-allowed'
                            )}
                            disabled={!hasDataChanged() || isLoading}
                        >
                            Save result
                        </button>
                    </div>
                )}

                <div className='mt-6 mb-2 flex items-center justify-between'>
                    <div className='inline-flex items-center space-x-4'>
                        <button
                            onClick={async () => setShowDeleteFile(true)}
                            className={classNames(
                                "inline-flex items-center text-gray-500 hover:text-gray-700",
                            )}
                        >
                            <CustomTrashIcon className="h-5 w-5" aria-hidden="true" />
                        </button>

                        <button
                            onClick={async () => await downloadFile()}
                            className={classNames(
                                "inline-flex items-center text-gray-500 hover:text-gray-700",
                            )}
                        >
                            <CustomDownloadIcon className="h-5 w-5" aria-hidden="true" />
                        </button>

                        <button
                            onClick={async () => await copyFileLink()}
                            className={classNames(
                                "inline-flex items-center text-gray-500 hover:text-gray-700",
                            )}
                        >
                            <CustomShareIcon className="h-5 w-5" aria-hidden="true" />
                        </button>

                        <Menu as="div" className="relative inline-block text-left overflow-visible">
                            <div className='z-50' onClick={() => setShowExportModal(true)}>
                                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                    Export As
                                    <ChevronRightIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                </Menu.Button>
                            </div>
                        </Menu>
                    </div>
                    <div className='inline-flex items-center'>
                        <button
                            onClick={async () => await previousFile()}
                            className={classNames(
                                "inline-flex items-center text-indigo-500 hover:text-indigo-700",
                                (!isPreviousFile() || isLoading) && 'opacity-50 cursor-not-allowed disabled:text-grey-500'
                            )}
                            disabled={!isPreviousFile() || isLoading}
                        >
                            <ArrowLeftIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                        <div className='mx-4 text-sm font-medium text-gray-700'>
                            <p>{currentIndex + 1} of {files.length}</p>
                        </div>
                        <button
                            onClick={async () => await nextFile()}
                            className={classNames(
                                "inline-flex items-center text-indigo-500 hover:text-indigo-700",
                                (!isNextFile() || isLoading) && 'opacity-50 cursor-not-allowed'
                            )}
                            disabled={!isNextFile() || isLoading}
                        >
                            <ArrowRightIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>
                <div className='pt-5 md:pt-0'></div>
            </div>
        </div >
    );
};

function ExportDialog({ open, setOpen, files, fileId, extraction, userId, extractionId }) {
    const cancelButtonRef = useRef(null);

    const arrayFields = extraction.fields.filter(field => field.type === 'array');

    const [fileFormat, setFileFormat] = useState('excel'); // Initially no file format selected
    const [exportType, setExportType] = useState('normal'); // Default export type is 'normal'
    const [selectedField, setSelectedField] = useState('');

    const handleFileExport = async () => {
        try {
            let currentDateTime = new Date().toISOString();
            let formattedDateTime = currentDateTime.slice(0, 10);
            let fileName = 'Extracta.ai - Data Export - ' + formattedDateTime;

            let outputArray = [];

            let currentFileId = fileId;
            let index = files.findIndex((file) => file.id === currentFileId);
            const file = files[index];

            outputArray = [{
                file_name: file.name,
                data: file.result,
            }];

            // if the file has more fields than the extraction's fields, remove the extra fields
            outputArray.forEach(item => {
                if (item.data !== null && item.data !== undefined) {
                    Object.keys(item.data).forEach(key => {
                        if (!extraction.fields.some(field => field.key === key)) {
                            delete item.data[key];
                        }
                    });
                }
            });

            // if the file has less fields than the extraction's fields, add the missing fields with a value of '-'
            extraction.fields.forEach(field => {
                outputArray.forEach(item => {
                    try {
                        if (item.data === null || item.data === undefined) {
                            if (!item.data.hasOwnProperty(field.key)) {
                                item.data[field.key] = '-';
                            }
                        }
                    } catch (error) { }
                });
            });

            const hasArrayField = extraction.fields.some(field => field.type === 'array');
            let extractionFields = extraction.fields;

            if ((fileFormat === 'excel' || fileFormat === 'csv') && hasArrayField) {
                if (exportType === 'multipleRows') {
                    const chosenArrayField = selectedField;
                    const { expandedOutput, newFields } = expandArrayField(outputArray, chosenArrayField, extraction.fields);
                    extractionFields = newFields;
                    outputArray = expandedOutput;
                }
            }

            switch (fileFormat) {
                case 'json':
                    handleGenerateJson(outputArray, fileName, extractionFields);
                    break;
                case 'excel':
                    handleGenerateExcel(outputArray, fileName, extractionFields);
                    break;
                case 'csv':
                    handleGenerateCsv(outputArray, fileName, extractionFields);
                    break;
                case 'word':
                    handleGenerateWord(outputArray, fileName);
                    break;
                default:
                    break;
            }

            toast.success('File exported successfully!');
            setOpen(false);
        } catch (error) {
            toast.error('Error exporting file!');
            setOpen(false);
        }
    }

    const expandArrayField = (outputArray, arrayFieldKey, fields) => {
        let expandedOutput = [];

        // Find the index of the original array field in the fields array
        const originalFieldIndex = fields.findIndex(field => field.key === arrayFieldKey);

        // Filter out the original array field from the new fields array (but only for object arrays)
        let newFields = fields.filter(field => field.key !== arrayFieldKey);

        // Find the arrayField structure in extraction.fields to get the properties
        const arrayFieldStructure = fields.find(field => field.key === arrayFieldKey);
        let properties = [];

        if (arrayFieldStructure && arrayFieldStructure.items && arrayFieldStructure.items.properties) {
            // Sort the properties based on the 'id' field to maintain the correct order
            properties = arrayFieldStructure.items.properties.sort((a, b) => a.id - b.id);
        }

        // Loop through each item in the outputArray
        outputArray.forEach(item => {
            let data = item.data;

            // Find the field that corresponds to the chosen array field
            let arrayField = data[arrayFieldKey];

            // If the field is not an array or does not exist, skip
            if (!Array.isArray(arrayField)) {
                expandedOutput.push(item); // Just push the original data if it's not an array
                return;
            }

            // Check if the array contains objects or strings
            if (typeof arrayField[0] === 'object' && !Array.isArray(arrayField[0])) {
                // Case where array items are objects (expand each object's properties into separate columns)
                arrayField.forEach(arrayEntry => {
                    let newRow = { ...data }; // Clone the original data

                    // Remove the arrayFieldKey from the newRow (we're expanding its fields)
                    delete newRow[arrayFieldKey];

                    // Spread object keys (from arrayEntry) into multiple columns in the new row using child keys only, keeping the order based on `properties`
                    properties.forEach(property => {
                        const key = property.key;
                        newRow[key] = arrayEntry[key];
                    });

                    // Add the expanded row to the output
                    expandedOutput.push({
                        ...item, // Keep the rest of the item info (e.g., file name)
                        data: newRow // Update data with the expanded row
                    });
                });

                // Insert the new fields at the index of the original array field
                const newFieldEntries = properties.map(property => ({
                    description: property.description,
                    key: property.key,
                    type: property.type,
                }));

                // Insert the new field entries at the original index
                newFields.splice(originalFieldIndex, 0, ...newFieldEntries);
            } else if (typeof arrayField[0] === 'string') {
                // Case where array items are strings (concatenate the strings with commas)
                let newRow = { ...data };

                // Join the array of strings into a single string, separated by commas
                newRow[arrayFieldKey] = arrayField.join(', ');

                // Add the updated row to the expanded output
                expandedOutput.push({
                    ...item, // Keep the rest of the item info
                    data: newRow // Update data with the concatenated string
                });

                // Ensure the original array field is retained in newFields (only if it's a string array)
                const originalField = fields.find(field => field.key === arrayFieldKey);
                if (originalField) {
                    newFields.splice(originalFieldIndex, 0, originalField);
                }
            }
        });

        return { expandedOutput, newFields }; // Return both the expanded output and the updated fields
    };

    const showWord = () => {
        const userIds = ['WgVLxXREk7eW2aQeFOSfRbuxJbm1', 'wZfRgTE69LO3BMn1gYtH4ovWeif2', 'uCiWcuKd3ebpbcblafD17Nb1ESV2', 'WOc41gZqwzQdewbi5uTdm3W0xlw1'];
        const extractionIds = ['-O8jj-L82xWGj4C8D7Mu', '-O86GTicjQx2F27obn0Y', '-O86NndgLlK1TKcIU1d_', '-O8jmir7skz8jJykD2H6'];
        return userIds.includes(userId) && extractionIds.includes(extractionId);
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-100" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-100 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6">
                                    <h2 className="text-xl font-semibold mb-6">Export Options</h2>

                                    <div className="space-y-4 mb-2">
                                        <div className="space-y-2">
                                            <label className="block text-sm font-medium text-gray-700">File Format</label>
                                            <div className="flex space-x-2">
                                                <button
                                                    className={`flex-1 px-4 py-2 border rounded-md ${fileFormat === 'excel' ? 'bg-indigo-600 text-white border-indigo-600' : 'border-gray-300'
                                                        }`}
                                                    onClick={() => setFileFormat('excel')}
                                                >
                                                    Excel
                                                </button>
                                                <button
                                                    className={`flex-1 px-4 py-2 border rounded-md ${fileFormat === 'csv' ? 'bg-indigo-600 text-white' : 'border-gray-300'
                                                        }`}
                                                    onClick={() => setFileFormat('csv')}
                                                >
                                                    CSV
                                                </button>
                                                <button
                                                    className={`flex-1 px-4 py-2 border rounded-md ${fileFormat === 'json' ? 'bg-indigo-600 text-white' : 'border-gray-300'
                                                        }`}
                                                    onClick={() => setFileFormat('json')}
                                                >
                                                    JSON
                                                </button>
                                                {showWord() && (
                                                    <button
                                                        className={`flex-1 px-4 py-2 border rounded-md ${fileFormat === 'word' ? 'bg-indigo-600 text-white' : 'border-gray-300'
                                                            }`}
                                                        onClick={() => setFileFormat('word')}
                                                    >
                                                        Word
                                                    </button>
                                                )}
                                            </div>
                                        </div>

                                        {fileFormat && (fileFormat !== 'json' && fileFormat !== 'word') && (
                                            <>
                                                <div className="space-y-2">
                                                    <label className="block text-sm font-medium text-gray-700">Export Type</label>
                                                    <div className="flex flex-col space-y-1">
                                                        <div className="flex items-center space-x-2">
                                                            <input
                                                                type="radio"
                                                                value="normal"
                                                                checked={exportType === 'normal'}
                                                                onChange={() => setExportType('normal')}
                                                                id="normal"
                                                                className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                                            />
                                                            <label htmlFor="normal" className="text-sm text-gray-700">
                                                                Normal Export
                                                            </label>
                                                        </div>
                                                        {arrayFields.length > 0 && (
                                                            <div className="flex items-center space-x-2">
                                                                <input
                                                                    type="radio"
                                                                    value="multipleRows"
                                                                    checked={exportType === 'multipleRows'}
                                                                    onChange={() => setExportType('multipleRows')}
                                                                    id="multipleRows"
                                                                    className="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                                                />
                                                                <label htmlFor="multipleRows" className="text-sm text-gray-700">
                                                                    Multiple Rows Export
                                                                </label>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="space-y-2">
                                                    <p className="text-sm text-gray-500">
                                                        {exportType === 'normal'
                                                            ? 'Normal Export: All fields are transformed into columns in the exported file.'
                                                            : 'Multiple Rows Export: Select a field of type array to be expanded across multiple rows.'}
                                                    </p>
                                                    {exportType === 'multipleRows' && (
                                                        <select
                                                            value={selectedField}
                                                            onChange={(e) => setSelectedField(e.target.value)}
                                                            className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                        >
                                                            <option value="" disabled>
                                                                Select a field
                                                            </option>
                                                            {arrayFields.map((field) => (
                                                                <option key={field.key} value={field.key}>
                                                                    {field.key}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm sm:ml-3 sm:w-auto ${!fileFormat || (fileFormat !== 'json' && exportType === 'multipleRows' && !selectedField)
                                            ? 'bg-gray-300 cursor-not-allowed text-gray-500'
                                            : 'bg-indigo-600 text-white hover:bg-indigo-500'
                                            }`}
                                        onClick={handleFileExport}
                                        disabled={!fileFormat || (fileFormat !== 'json' && exportType === 'multipleRows' && !selectedField)}
                                    >
                                        Export File
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        ref={cancelButtonRef}
                                        onClick={() => setOpen(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

function UnsavedChangesAlert({ open, setOpen, nextMoveAfterSaving, saveChanges, discardChanges }) {
    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog className="relative z-100" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-100 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationTriangleIcon className="h-6 w-6 text-orange-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                Unsaved Changes
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                {nextMoveAfterSaving === 'next' && (
                                                    <p className="text-sm text-gray-500">
                                                        You have unsaved changes. Do you want to save them before procedding to the next file?
                                                    </p>)}

                                                {nextMoveAfterSaving === 'previous' && (
                                                    <p className="text-sm text-gray-500">
                                                        You have unsaved changes. Do you want to save them before procedding to the previous file?
                                                    </p>)}

                                                {nextMoveAfterSaving === 'close' && (
                                                    <p className="text-sm text-gray-500">
                                                        You have unsaved changes. Do you want to save them before closing the modal?
                                                    </p>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                                        onClick={saveChanges}
                                    >
                                        Save Changes
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={discardChanges}
                                        ref={cancelButtonRef}
                                    >
                                        Discard Changes
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

function ShowDeleteModalFile({ open, setOpen, deleteFunction }) {
    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-100" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-100 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                Confirm Deletion
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Are you sure you want to delete this file? This action cannot be undone.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                        onClick={() => deleteFunction()}
                                    >
                                        Delete
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

function DisplayResult({
    result,
    extraction,
    handleInputChangeString,
    handleInputChangeObject,
    handleInputChangeArrayString,
    handleInputChangeArrayObject,
    addInputArrayString,
    addInputArrayObject,
    deleteInputArrayString,
    deleteInputArrayObject,
    isLoading
}) {
    if (isLoading) {
        return (
            <>
                {[...Array(5)].map((_, i) => (
                    <div key={i} className="mb-4 flex flex-col gap-x-2 p-4 border border-gray-200 rounded-lg bg-slate-50">
                        <div className="flex items-center">
                            <span className="w-1/2 font-bold break-words pr-4">
                                <Skeleton />
                            </span>
                            <div className="w-1/2 relative">
                                <Skeleton />
                            </div>
                        </div>
                    </div>
                ))}
            </>
        )
    }

    return (
        <div className='flex flex-col gap-y-4'>
            {extraction.fields.map((field, index) => {
                let fieldType = field.type ?? 'string';
                let itemsType = field.items?.type ?? 'string';
                let key = field.key;

                if (fieldType === "string") {
                    let resultData = result[key] ?? '';

                    return (
                        <div
                            className="flex flex-col gap-x-2 p-4 border border-gray-200 rounded-lg bg-slate-50"
                            key={index}
                        >
                            <div className="flex items-center">
                                <span className="w-1/2 font-bold break-words pr-4">
                                    {key}
                                </span>
                                <div className="w-1/2 relative">
                                    <input
                                        onChange={(e) => {
                                            e.preventDefault();
                                            handleInputChangeString(key, e.target.value);
                                        }}
                                        type="text"
                                        name="value"
                                        id="value"
                                        value={resultData}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="add a value"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    )
                } else if (fieldType === "object") {
                    let resultData = result[key] ?? {};

                    // Extract and sort the keys based on their IDs from field.properties
                    // const sortedInnerKeys = Object.keys(resultData).sort((a, b) => {
                    //     const idA = field.properties.find(property => property.key === a).id;
                    //     const idB = field.properties.find(property => property.key === b).id;
                    //     return idA - idB;
                    // });
                    const sortedInnerKeys = Object.keys(resultData).sort((a, b) => {
                        const propertyA = field.properties.find(property => property.key === a);
                        const propertyB = field.properties.find(property => property.key === b);

                        const idA = propertyA ? propertyA.id : Number.MAX_SAFE_INTEGER;
                        const idB = propertyB ? propertyB.id : Number.MAX_SAFE_INTEGER;

                        return idA - idB;
                    });

                    return (
                        <div
                            className="flex flex-col gap-x-2 p-4 border border-gray-200 rounded-lg bg-slate-50"
                            key={index}
                        >
                            <div className="mb-4">
                                <span className="font-bold">
                                    {key}
                                </span>
                            </div>
                            <div className="flex flex-col pl-4 gap-y-3.5">
                                {sortedInnerKeys.map((innerKey, indexKey) => {
                                    return (
                                        <div className="flex items-center">
                                            <span
                                                key={indexKey}
                                                className={classNames(
                                                    'inline-block h-2 w-2 flex-shrink-0 rounded-full mr-3.5',
                                                    true ? 'bg-indigo-500' : 'bg-gray-200'
                                                )}
                                                aria-hidden="true"
                                            />
                                            <div className="relative w-full">
                                                <label
                                                    htmlFor="name"
                                                    className="absolute -top-2 left-2 inline-block bg-white rounded px-1 text-xs font-medium text-gray-900"
                                                >
                                                    {innerKey}
                                                </label>
                                                <input
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        handleInputChangeObject(key, innerKey, e.target.value);
                                                    }}
                                                    type="text"
                                                    name="value"
                                                    id="value"
                                                    value={resultData[innerKey]}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    placeholder="add a value"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                } else if (fieldType === "array" && itemsType === "string") {
                    let resultData = result[key] ?? [];

                    return (
                        <div
                            className="flex flex-col gap-x-2 p-4 border border-gray-200 rounded-lg bg-slate-50"
                            key={index}
                        >
                            <div className="mb-3">
                                <span className="font-bold">
                                    {key}
                                </span>
                            </div>
                            <div className="flex flex-col pl-4 gap-y-3.5">
                                {Object.keys(resultData).map((innerKey, indexKey) => {
                                    return (
                                        <div className="flex items-center">
                                            <span className='mr-2.5 font-bold text-indigo-500'>
                                                {indexKey + 1}.
                                            </span>
                                            <div className="relative w-full">
                                                <input
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        handleInputChangeArrayString(key, indexKey, e.target.value);
                                                    }}
                                                    type="text"
                                                    name="value"
                                                    id="value"
                                                    value={resultData[innerKey]}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    placeholder="add a value"
                                                    required
                                                />
                                            </div>
                                            {/* remove button if there are multiple items */}
                                            {resultData.length > 1 && (
                                                <button
                                                    onClick={() => deleteInputArrayString(key, indexKey)}
                                                    className={classNames(
                                                        'bg-red-400 hover:bg-red-500 w-4 h-4 flex items-center justify-center rounded-full ml-3 p-1',
                                                    )}
                                                    aria-label="Remove"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
                                                    </svg>
                                                </button>
                                            )}
                                        </div>
                                    )
                                })}
                                <button
                                    onClick={() => addInputArrayString(key)}
                                    className="pl-7 inline-flex items-center gap-x-1.5 text-sm font-semibold text-indigo-600 hover:underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Add item
                                </button>
                            </div>
                        </div>
                    )
                } else if (fieldType === "array" && itemsType === "object") {
                    let resultData = result[key] ?? [];

                    return (
                        <div
                            className="flex flex-col gap-x-2 p-4 border border-gray-200 rounded-lg bg-slate-50"
                            key={index}
                        >
                            <div className="mb-4">
                                <span className="font-bold">
                                    {key}
                                </span>
                            </div>
                            <div className="flex flex-col pl-4 gap-y-3.5">
                                {Object.keys(resultData).map((innerKey, indexKey) => {
                                    let objectData = resultData[innerKey];

                                    // const sortedKeys = Object.keys(objectData).sort((a, b) => {
                                    //     const idA = field.items.properties.find(property => property.key === a).id;
                                    //     const idB = field.items.properties.find(property => property.key === b).id;
                                    //     return idA - idB;
                                    // });

                                    const defaultId = Number.MAX_SAFE_INTEGER; // A large number to ensure properties without IDs are at the end

                                    const sortedKeys = Object.keys(objectData).sort((a, b) => {
                                        const propertyA = field.items.properties.find(property => property.key === a);
                                        const propertyB = field.items.properties.find(property => property.key === b);

                                        const idA = propertyA && propertyA.id !== undefined ? propertyA.id : defaultId;
                                        const idB = propertyB && propertyB.id !== undefined ? propertyB.id : defaultId;

                                        return idA - idB;
                                    });

                                    return (
                                        <div className="flex items-start">
                                            <span className='inline-block h-2 w-2 flex-shrink-0 rounded-full mr-4 font-bold text-indigo-500'>
                                                {indexKey + 1}.
                                            </span>
                                            <div className="w-full flex flex-col gap-y-4">
                                                {sortedKeys.map((innerInnerKey) => {
                                                    return (
                                                        <div className="flex items-center">
                                                            <div className="relative w-full">
                                                                <label
                                                                    htmlFor="name"
                                                                    className="absolute -top-2 left-2 inline-block bg-white rounded px-1 text-xs font-medium text-gray-900"
                                                                >
                                                                    {innerInnerKey}
                                                                </label>
                                                                <input
                                                                    onChange={(e) => {
                                                                        e.preventDefault();
                                                                        handleInputChangeArrayObject(key, indexKey, innerInnerKey, e.target.value);
                                                                    }}
                                                                    type="text"
                                                                    name="value"
                                                                    id="value"
                                                                    value={objectData[innerInnerKey]}
                                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                    placeholder="add a value"
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                {/* add divider if object data is not the last one */}
                                                {indexKey !== resultData.length - 1 && (
                                                    <div className="border-t border-gray-200 mb-1"></div>
                                                )}
                                            </div>
                                            {resultData.length > 1 && (
                                                <button
                                                    onClick={() => deleteInputArrayObject(key, indexKey)}
                                                    className={classNames(
                                                        'bg-red-400 hover:bg-red-500 w-4 h-4 flex items-center justify-center rounded-full ml-3 p-1 mt-2',
                                                    )}
                                                    aria-label="Remove"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
                                                    </svg>
                                                </button>
                                            )}
                                        </div>
                                    )
                                })}
                                <button
                                    onClick={() => addInputArrayObject(key)}
                                    className="pl-7 inline-flex items-center gap-x-1.5 text-sm font-semibold text-indigo-600 hover:underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Add item
                                </button>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div></div>
                    )
                }
            })}
        </div >
    )
}

export default ViewFile;